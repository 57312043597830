.globalPositionCards--cards{
    .boxValue{
        position: relative;
        text-align: center;

        @media (max-width: 1024px){
            text-align: center;
        }

        &:before{
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            left: -5%;
        }

        &.box--first{
            &:before{
                display: none;
            }
        }
    }

    .totalBox{
        gap: 20px;
        position: relative;

        &:before{
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            left: 50%;
        }

        &--total1{
            width: 100%;

            .totalBox__item{
                max-width: calc(50% - 10px);
            }
        }

        &--total3{

            @media (max-width: 460px){
                grid-template-columns: 1.2fr 1fr 1fr;
            }


            &:before{
                left: 36%;

                @media (max-width: 768px){
                    left: 40%
                }

                @media (max-width: 576px){
                    left: 46%
                }

                @media (max-width: 460px){
                    left: 33%
                }
            }

            &:after{
                content: '';
                position: absolute;
                width: 1px;
                height: 100%;
                top: 0;
                left: 72%;

                @media (max-width: 576px){
                    left: 77%
                }

                @media (max-width: 460px){
                    left: 67%
                }
            }
        }
    }
}