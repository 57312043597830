.plt-ios{
  /*
  #header{
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      position: fixed;
    }
  }*/
}

#header{
  z-index: 19;
  position: relative;

  a{
    text-decoration: none!important;
  }

  [data-component-name="MainNavigationBar"]{

    nav{
      margin-left: -46px;
      /*button{
        margin: 0 12px;
      }*/
    }
    .right{
      span{
        font-size: 1rem;
      }
    }
  }
  .menu-nav{
    &.fixed{
      [data-component-name="IconButton"]{
        display: none!important;
      }
      .logo{
        margin-left: -25px;
      }

      header{
        transition: transform .3s;
      }

      &.searcher{
        header{
          @media only screen and (max-width: 1024px) {
            transform: translateY(80px);
          }
        }
      }
    }
    nav{
      span{
        font-size: 1rem;
      }
    }
  }
  /*&.inverse{
    .o0dvt10{
      opacity: 0.1;
    }

  }*/

  .menu-logo{
    position: relative;
    z-index: 2;
    &:not(.small){
      [data-component-name="MainNavigationBar"]{
        border-bottom-width:0!important;
      }
    }
  }

  .menu-nav{
    position: relative;
    z-index: 0;
    [data-component-name="MainNavigationBar"]{
      border-bottom-width:0!important;
    }

    &.landing{
      nav{
        > div{
          width:auto;
        }
      }
    }

    nav{
      > div{
        width: 1300px;
        max-width: calc(100vw - 40px);
      }
    }

    &.fixed{
      position: inherit;
      [data-component-name="MainNavigationBar"]{
        border-bottom-width: 1px!important;
      }


      &.landing{
        nav{
          > div{
            width:auto;
          }
        }
      }

      nav{
        margin-left: 0;
        width: max-content;
        >div{
          width: 1102px;
          max-width: calc(100vw - 225px);
        }

        span{
          font-size: 1rem;
        }
      }

    }
  }
  .menu-vertical{
    background-image: url('../../../public/assets/img/mask-bg-menu.svg');
    background-repeat: no-repeat;
    background-position: -80vw 50vh;
    height: 100vh;
    overflow: auto;
    position: fixed;
    transform: translate3d(0,0,0);
    z-index: 999;
    background-color: #fff;
    padding-bottom: 70px;
    z-index: 4;


    margin-top: -140vh;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;

    &.visible{
      margin-top:0px;
      opacity: 1;
    }
    .option-selected{
        width:4px;
        height:72px;
        position: relative;
        right: -16px;
    }

  }
  .language-selector{

      position: absolute;
      transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      opacity: 0;

      margin-top: -40px;
      z-index: -1;

      &.visible{
        margin-top: 4px;
        opacity: 1;
        z-index: 3;
      }
  }
}