.table--operationsInProgress-new{
    .row{
        margin: 6px 0 8px;
    }

    .col-1,
    .col-2{
        width: 160px;
    }

    .col-3{
        width: 200px;
    }

    .col-4{
        width: 120px;
    }

    .col-4{
        width: 140px;
        text-align: center;
    }

    .col-5{
        width: 75px;
        text-align: center;
    }
}

.table--operationsInProgress-operations{
    .row{
        margin: 6px 0 8px;
    }

    .col-1,
    .col-2,
    .col-3{
        width: 130px;
    }

    .col-4{
        width: 200px;
    }

    .col-5{
        width: 110px;
    }

    .col-6{
        width: 140px;
        text-align: center;
    }

    .col-7{
        width: 70px;
        text-align: center;
    }

    @media only screen and (min-width: 1224px){
        .col-1,
        .col-2,
        .col-3{
            width: 110px;
        }

        .col-4{
            width: 170px;
        }

        .col-5{
            width: 95px;
        }
    }
}

.tabs--operationsInProgress{
    position: fixed;
    left: 0;
    top:calc(35px + env(safe-area-inset-top))
}
.with-tabs--operationsInProgress{
    margin-top: 32px;
}

.plt-ios{
    .with-tabs--operationsInProgress{
        @supports (margin-top: env(safe-area-inset-top)) {
            margin-top:env(safe-area-inset-top);

        }
    }
}

.detail-operationsInProgress{
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    &.visible{
        max-height: 500vh;
        opacity: 1;
    }
}

.scrollable-container {
    width: 300px;
    height: 400px;
    overflow: hidden;
    position: relative;
}

.scrollable-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding-right: 17px;
}

.scrollable-content::-webkit-scrollbar {
    width: 0.8em;
}

.scrollable-content::-webkit-scrollbar-track {
    background-color: transparent;
}
