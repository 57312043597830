@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Regular.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Light.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Medium.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Bold.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


/*
@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Black_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Black_Italic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Black.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_DemiBold_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_DemiBold_Italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_ExtraBold.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_DemiBold.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_ExtraLight.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Bold_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Bold_Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Light_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Light_Italic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans-HairlineItalic';
    src: url('./fonts/Telefonica_Sans_Hairline_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Hairline_Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_ExtraBold_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_ExtraBold_Italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_ExtraLight_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_ExtraLight_Italic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans-Hairline';
    src: url('./fonts/Telefonica_Sans_Hairline.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Hairline.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Medium.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Thin_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Thin_Italic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Medium_Italic.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Medium_Italic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'TelefonicaSans';
    src: url('./fonts/Telefonica_Sans_Thin.woff2') format('woff2'),
        url('./fonts/Telefonica_Sans_Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}*/




body {
    font-family: -apple-system, 'TelefonicaSans', 'Helvetica', 'Arial', sans-serif;
}

input,
textarea,
pre,
code {
    font: inherit;
}

* {
    font-family: -apple-system, 'TelefonicaSans', 'Helvetica', 'Arial', sans-serif;
}