.content-formalize-contract {
    min-height: 100vh;

    .form{
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        > div{
            max-width: 800px;
            margin: 0 auto;
        }
        &.large{
            max-width: 832px;
            margin: 0 auto;

            > div{
                max-width: 832px;
                margin: 0 auto;
            }
        }
        &.extra-large{
            max-width: 912px;
            margin: 0 auto;

            > div{
                max-width: 912px;
                margin: 0 auto;
            }
        }
    }
}

#iframeSignaturitFormalizeContract{
    height:calc(100vh - 178px);
}

@media only screen and (max-width: 1023px) {
    #iframeSignaturitFormalizeContract{
        height:calc(100vh - 114px);
    }
}


