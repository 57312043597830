.table--taxInformationPlans{
    .row{
        margin: 6px 0 8px;
    }

    .col-2,
    .col-3,
    .col-4,
    .col-5{
        text-align: center;
    }

    .col-1{
        width: 220px;
    }

    .col-2,
    .col-3,
    .col-4,
    .col-5{
        width: 90px;
    }

    @media only screen and (min-width: 1190px){
        .col-1{
            width: 280px;
        }

        .col-2,
        .col-3,
        .col-4,
        .col-5{
            width: 135px;
        }
    }
}

.table1--taxInformationFunds{
    .row{
        margin: 6px 0 8px;
    }

    .col-2,
    .col-3,
    .col-4,
    .col-5{
        text-align: center;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5{
        width: 121px;
    }

    @media only screen and (min-width: 1190px){
        .col-1,
        .col-2,
        .col-3,
        .col-4,
        .col-5{
            width: 160px;
        }
    }
}

.table2--taxInformationFunds{
    min-width: 950px;
    width: 950px;

    .row{
        margin: 6px 0 8px;
    }

    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8{
        text-align: center;
    }

    .col-1{
        width: 80px;
    }

    .col-2{
        width: 100px;
    }

    .col-3{
        width: 110px;
    }

    .col-6{
        width: 45px;
    }

    .col-4,
    .col-5,
    .col-7{
        width: 68px;
    }

    .col-8,
    .col-9{
        width: 50px;
    }

    @media only screen and (min-width: 1024px){  
        min-width: 0;
        width: auto;

        .col-1{
            width: 80px;
        }

        .col-2{
            width: 100px;
        }

        .col-3{
            width: 110px;
        }

        .col-6{
            width: 45px;
        }

        .col-4,
        .col-5,
        .col-7{
            width: 68px;
        }

        .col-8,
        .col-9{
            width: 50px;
        }
    }
}

.overflowX{
    overflow-x: auto;
}