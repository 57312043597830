.noImageHero{
    position: relative;

    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: calc(100% - 150px);
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 1;
    }

    &--noChildren{
        &:before{
            height: 100%;
        }
    }

    &__content{
        position: relative;
        z-index: 2;
    }

    &--fullHead{
        max-width: calc(100% - 200px);
        width: 100%;

        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
}

.imageHero{
    &__image{
        @media (max-width: 1024px) {
            display: flex;
            justify-content: center;
        }

        img{
            background: transparent !important;
        }
    }
}

.hero--html{
    margin: 16px 0;
    font-size: 18px;
    line-height: 24px;

    p{
        margin-bottom: 24px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 24px;
    }
}

.calculatorHero{
    position: relative;
    overflow: hidden;

    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: calc(100% - 125px);
        min-height: 450px;
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 1;

        @media (max-width: 1024px) {
            height: 100%;
            min-height: 0;
        }
    }

    &__left{
        position: relative;
        z-index: 2;
    }

    &__right{
        display: flex;
        justify-content: flex-end;
        position: relative;
        z-index: 2;

        @media (max-width: 1024px) {
            justify-content: center;
        }
    }
}

.heroShadow{
    position: relative;
    padding-top: 127px !important;

    @media (max-width: 768px) {
        padding-top: 48px !important;
    }

    &__background{
        position: absolute;
        width: 100%;
        height: calc(100% - 150px);
        top: 0;
        left: 0;
        overflow: hidden;
        z-index: 1;
    }

    &__color{
        position: relative;
        width: 100%;
        height: 100%;

        @media (max-width: 768px) {
            overflow: hidden;
            height: calc(100% - 166px);
        }

        &:before{
            content: "";
            width: 378px;
            height: 378px;
            position: absolute;
            background: radial-gradient(circle, rgba(103,224,229,1) 0%, rgba(103,224,229,0.34) 100%);;
            filter: blur(50px);
            border-radius: 100%;
            bottom: -144px;
            left: calc(50% - 130px);
            z-index: 1;

            @media (max-width: 768px) {
                width: 160px;
                height: 160px;
                bottom: -20px;
                left: -20px;
            }
        }

        &:after{
            content: '';
            width: 541px;
            height: 541px;
            position: absolute;
            background: radial-gradient(circle, rgba(0,102,255,1) 0%, rgba(0,102,255,0.63) 100%);
            filter: blur(50px);
            border-radius: 100%;
            bottom: -30px;
            left: calc(50% + 270px);
            z-index: 1;

            @media (max-width: 768px) {
                width: 236px;
                height: 236px;
                bottom: 15px;
                left: calc(50% - 40px);
            }
        }
    }

    .imageBox{
        position: absolute;
        width: 950px;
        height: 950px;
        top: 90px;
        // right: -130px;
        left: calc(50% - 103px);

        @media (max-width: 768px) {
            width: 555px;
            height: 555px;
            top: initial;
            bottom: -260px;
            left: calc(50% - 356px);
        }

        &__radial{
            position: relative;
            overflow: hidden;
            border-radius: 50%;
            z-index: 2;
        }
    }

    &__content{
        position: relative;
        z-index: 2;

        .textBox{
            max-width: 576px;
            width: 100%;

            @media (max-width: 768px) {
                max-width: 100%;
                padding-bottom: 330px;
            }
        }
    }

    &.fullHeight{
        min-height: 520px;

        @media (max-width: 1024px) {
            min-height: 270px;
        }

        @media (max-width: 768px) {
            min-height: 0;
        }

        .heroShadow__background{
            height: 100%;
        }

        .heroShadow__color{
            @media (max-width: 768px) {
                height: 100%;
            }

            &:before{
                left: calc(50% - 80px);

                @media (max-width: 1024px) {
                    bottom: -240px;
                    left: calc(50% - 40px);
                }

                @media (max-width: 768px) {
                    display: none;
                }
            }

            &:after{
                bottom: -94px;
                left: calc(50% + 290px);

                @media (max-width: 1024px) {
                    bottom: -225px;
                }

                @media (max-width: 768px) {
                    width: 236px;
                    height: 236px;
                    bottom: 15px;
                    left: 190px;
                }
            }
        }

        .heroShadow__content{
            .textBox{
                @media (max-width: 1024px) {
                    max-width: 395px;
                }

                @media (max-width: 768px) {
                    max-width: 100%;
                    padding-bottom: 376px;
                }
            }
        }

        .imageBox{
            width: 850px;
            height: 850px;
            top: 70px;
            left: calc(50% - 53px);
            [data-component-name="Image"]{
                img{
                    height:475px;
                }
            }

            @media (max-width: 1024px) {
                top: 17px;
                left: calc(50% + 2px);
            }

            @media (max-width: 768px) {
                width: 555px;
                height: 555px;
                top: initial;
                bottom: -260px;
                left: -170px;

                [data-component-name="Image"]{
                    img{
                        height:325px;
                    }
                }
            }
        }
    }
}