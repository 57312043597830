/* Onboarding page */
.onboardingApp{
    width:100vw;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 999;
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom) / 7;
    transform: translate3d(0,0,0);

    &.hide{
        display: none;
    }

    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center top;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0,0,0,0.5);
    }

    &:after{
        content: '';
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.16);
        z-index: 2;
        top: 0;
        left: 0;
        position: absolute;
    }

    > div{
        height: 100%;
        position: relative;
        z-index: 3;
    }

    [data-component-name="Carousel"]{
        pointer-events: none;
        button[data-component-name="Touchable"]{
            display: none!important;
        }
        [data-item="true"]{
            width: 100vw !important;
            padding: 0 !important;
            scroll-margin: 0;
        }
    }

    &__slider{
        position: relative;
        z-index: 2;

        .step{
            height: calc(100vh - 52px - env(safe-area-inset-bottom) - env(safe-area-inset-top));
            position: relative;

            .stepText{
                position: absolute;
            }

            img{
                border-radius: 0;
            }

            .stepIcon{
                display: flex;
                align-content: flex-start;
                justify-content: flex-start;
                margin-top: 10px;
                position: relative;

                .iconHandUp{
                    margin-top: 10px;
                    -webkit-animation: slide-horizontal 2.45s ease-out infinite both;
	                        animation: slide-horizontal 2.45s ease-out infinite both;
                }

                .iconChevronLeft{
                    position: relative;
                    left: -20px;

                    .iconChevron{
                        position: relative;
                        top: 3px;

                        &.last{
                            left: -2px;
                        }
                    }
                }
            }

            &.step-1{
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &.step-2{
                display: flex;
                align-items: flex-start;
                justify-content: center;

                .stepImage{
                    padding-left: 155px;
                }
            }

            &.step-3{
                padding-right: 0;
                display: flex;
                align-items: center;
            }

            &--textHome{
                top: calc(50% - 264px);
                left: calc(50% - 169px);
                width: 208px;

                @media (max-width: 375px){
                    top: calc(50% - 250px);
                    left: 20px;
                }

                @media (max-height: 570px){
                    top: calc(50% - 214px);
                    left: calc(50% - 130px);
                }
            }

            &--textProgress{
                top: calc(50% - 184px);
                left: calc(50% - 90px);
                width: 208px;

                @media (max-width: 375px){
                    top: calc(50% - 155px);
                }

                @media (max-height: 570px){
                    top: calc(50% - 120px);
                    left: calc(50% - 58px);
                }
            }

            &--textContract{
                top: calc(50% - 74px);
                left: calc(50% - 18px);
                width: 190px;

                @media (max-width: 375px){
                    top: calc(50% - 65px);
                    left: initial;
                    right: 16px;
                    width: 180px;
                }

                @media (max-height: 570px){
                    top: calc(50% - 30px);
                    left: calc(50% + 10px);
                    width: 165px;
                }
            }

            &--textMail{
                top: calc(50% + 129px);
                left: calc(50% - 98px);
                width: 190px;
                text-align: right;

                @media (max-width: 375px){
                    top: calc(50% + 118px);
                    width: 175px;
                    left: initial;
                    right: 90px;
                }

                @media (max-height: 570px){
                    top: calc(50% + 101px);
                    left: calc(50% - 110px);
                    width: 170px;
                }
            }

            &--textMenu{
                top: calc(50% + 222px);
                left: calc(50% - 33px);
                width: 200px;
                text-align: right;

                @media (max-width: 375px){
                    top: calc(50% + 205px);
                    left: initial;
                    right: 20px;
                    width: 150px;
                }

                @media (max-height: 570px){
                    top: calc(50% + 191px);
                    left: calc(50% - 30px);
                    width: 165px;
                }
            }

            &--textUser{
                text-align: center;
                position: relative !important;
                width: 100%;
                max-width: 243px;
                margin: 16px auto 0;
            }

            &--textPlans{
                position: relative !important;
                width: calc(100% - 16px);
                margin-bottom: 16px;
            }
        }
    }

    &__footer{
        position: relative;
        z-index: 2;
    }
}

@-webkit-keyframes slide-horizontal {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes slide-horizontal {
    0% {
        transform: translateX(0);
        opacity: .4;
    }

    50% {
        transform: translateX(8px);
        opacity: 1;
    }

    100% {
        transform: translateX(0);
        opacity: .4;
    }
}