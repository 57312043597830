.infoContract--content{
    @media (max-width: 1024px){
        border-radius: 0 !important;
        position: relative;

        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            top: 0;
            left: 0;
        }
    }

    @media (min-width: 1024px){
        &.box--auto{
            width: auto;
            display: inline-block;
    
            .boxValue{
                min-width: 145px;
            }
        }
    }

    .boxValue{
        position: relative;

        @media (max-width: 1024px){
            text-align: center;
        }

        &:before{
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            top: 0;
            left: -15%;

            @media (max-width: 1024px){
                display: none;
            }
        }

        &.box--first{
            &:before{
                display: none;
            }
        }
    }
}