.form-incomplete-register-page{
    max-width: 1016px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    min-height: calc(100vh - 80px);


    @media only screen and (max-width: 1023px) {
        min-height: calc(100vh - 57px);

    }

}
.nativeapp{
    .form-incomplete-register-page{
        min-height: 100vh;
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }
}