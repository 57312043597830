.copyToClipBoard--copy {
    position: absolute;
    top: 100px;
    left: 60px;

    @media screen and (max-width: 1024px) {
        top: 80px;
        left: 60px;
      }

    @media screen and (max-width: 768px) {
      top: 75px;
      left: 60px;
    }
}