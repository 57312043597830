ion-app{

    &::before,
    &::after{
        opacity: 0;
        content:'';
    }

    &.app-pause{
        &::before{
            opacity: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
            background: linear-gradient(115deg, #06F 6.38%, rgba(0, 148, 255, 0.79) 164.5%), #FFF;
            z-index: 9998;
        }

        &::after{
            opacity: 1;
            background: url(../../public/assets//img/logo-inverse.svg) no-repeat center center;
            background-size: contain;
            width: 50%;
            height: 50%;
            top: 25%;
            left: 25%;
            position: absolute;
            z-index: 9999;
        }
    }


    &::before,
    &::after{
        opacity: 0;
        content:'';
    }

    &.app-loading{

        &::before{
            opacity: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            left:0;
            background: #FFF;
            z-index: 9998;
        }

        &.app{
            &::before{
                opacity: 1;
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                left:0;
                background: linear-gradient(115deg, #06F 6.38%, rgba(0, 148, 255, 0.79) 164.5%), #FFF;
                z-index: 9998;
            }

            &::after{
                opacity: 1;
                background: url(../../public/assets//img/logo-inverse.svg) no-repeat center center;
                background-size: contain;
                width: 50%;
                height: 50%;
                top: 25%;
                left: 25%;
                position: absolute;
                z-index: 9999;
            }
        }
    }
}

ion-header {
    border-radius: 0;
	ion-toolbar {
		border-radius: 0;
		overflow: hidden;
	}
    .header-background{
        display: none;
    }
}

ion-content{
    border-radius: 0;
    position: relative;
    top:-1px;
    --keyboard-offset: 0!important;


    &::part(scroll) {
        overflow: overlay!important;
    }
}

.full-height{

    &.header-small{
        min-height: calc(100vh - 80px);


        @media only screen and (max-width: 1024px) {

            min-height: calc(100vh - 44px);
        }
    }
}

form{
    transition: all 0.3s;
    &.loading{
        opacity: 0.7;
        pointer-events: none;
    }

    [value]:not([value=""]){
        &[disabled][type=date]:not(:valid):not(:focus)::-webkit-datetime-edit{
            color:inherit!important;
            opacity: 1!important;
        }
    }
}

.loadingBox{
    transition: all 0.3s;

    &.loading{
        opacity: 0.7;
        pointer-events: none;
    }
}

.tabs--fixed{
    position: fixed;
    left: 0;
    top: calc(35px + env(safe-area-inset-top));
    z-index: 5;
}

.with-tabs--fixed{
    margin-top: 48px;

    &.min{
        margin-top:24px;
    }
}

.info-button--operatePlan{
    position: fixed;
    transform: translate3d(0,0,0);
    right: 16px;
    width: auto!important;
    z-index: 999;
    top: 10px;

    &.is-native-app{
        &.is-ios{
            top: -4px;
            margin-top: env(safe-area-inset-top);
        }
    }
}

.info--operatePlan{
    &.fixed{
        max-height: 77vh;
        overflow: auto;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        padding: 16px 16px 0!important;
        transform: translate3d(0,0,0);
        position: fixed!important;
        width: 100vw!important;
        left:0!important;
        margin-top: -500px;
        z-index: 6;

        .contractInfo--content{
            border-radius: 0!important;
        }

        &.visible{
            opacity: 1;
            margin-top: -48px;
        }
    }
}

.stepper--operations{
    @media (max-width: 1024px){
        margin-bottom: 16px;
    }

    .stepper{
        display: inline-block;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;

        > div{
            max-width: 600px;
            margin: 24px auto 16px;
        }
    }

    &.stepper--fixed{
        width: 100%;
        position: fixed;
        left: 0;
        top: calc(35px + env(safe-area-inset-top));
        z-index: 5;
    }
}

.with-stepper--fixed{
    margin-top: 70px;

    // &.min{
    //     margin-top:24px;
    // }
}

.html-content{
    line-height: 26px;
    p{
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 26px;
    }
    a, a:focus, a:visited {
        text-decoration: none;
        -webkit-transition: opacity 1.3s ease-in-out;
        -moz-transition: opacity 1.3s ease-in-out;
        -o-transition: opacity 1.3s ease-in-out;
        -ms-transition: opacity 1.3s ease-in-out;
        cursor: pointer;
    }

    a{
        color:#0066ff;
    }

    b, strong
    {
        font-weight: 500;
    }




    h1 {
        font-size: 40px;
        line-height: 35px;
        margin-bottom: 31px
    }

   h2 {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 31px
    }

    h3 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 31px
    }

    h4 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 31px
    }

   h5 {
        font-size: 20px;
        line-height: 35px;
        margin-bottom: 31px
    }

    h6 {
        line-height: 35px;
        margin-bottom: 31px
    }



    h2,
    h3 {
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 15px
    }

    h5 {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
        margin-bottom: 15px
    }

    h6 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        margin-bottom: 15px
    }

    img {
        max-width: 100%;
        height: auto
    }

   ul, ol {
        list-style: inherit!important;

        -webkit-margin-before: 1.5em;
        -webkit-margin-after: 1.5em;
        -webkit-margin-start: 0;
        -webkit-margin-end: 0;
        -webkit-padding-start: 45px;

        li {
            margin-bottom: 8px;
            line-height: 20px;
            text-align: justify;
            position: relative;
            margin: 5px 0px 10px;
        }
    }

    ol {
         list-style: decimal!important
     }
}

.collapsibleBlock--content{
    font-size: 16px;
    line-height: 24px;
    .html-content{
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
}


.carousel-data-card{
    &.without-top{
        margin-top:-130px;
    }
}

.plt-ios{
    [data-component-name="FixedFooter"]{
        @media only screen and (max-width: 1024px) {
            margin-bottom:10px!important;
        }
    }
}

[data-component-name="PosterCard"]{
    z-index:0;
}

[data-component-name="RowList"][data-remove-paddings="true"]{
    >div{
      padding-left: 0;
      padding-right: 0;
    }
    [data-component-name="Row"]{
     > div{
      padding-left: 0;
      padding-right: 0;

      > div{
        padding: 0 16px;
      }
     }
    }
  }

// [data-component-name="Tab"]{
//     span{
//         font-weight: 300 !important;
//     }
// }

[data-component-name="Dialog"]{
    .dialog-without-buttons{
        > div{
            &:first-child{
                overflow-y: hidden;
                padding: 0px 0 32px;
            }
            padding: 8px 0;
            max-height: 80vh;
            overflow-x: hidden;
            overflow-y: auto;
            width: calc(100% + 16px);
            padding-right: 16px;
            &:last-child{
                display: none;
            }
        }

        &.multiple-lines{
            > div{
                max-height: 50vh;
            }
        }
    }
}

.toastCallout{
    max-width: 500px;
    width: calc(100% - 32px);
    /*margin: 0 auto;
    position: fixed;
    bottom: -100px;
    left: 16px;

    @media only screen and (min-width: 532px){
        width: 100%;
        left: calc(50% - 250px);
    }

    &.visible{
        bottom: 40px;
        -webkit-animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	            animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    }

    &.closed{
        -webkit-animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
                animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    }*/
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
 /*
@-webkit-keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
}
@keyframes slide-in-blurred-bottom {
    0% {
      -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
              transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
}*/

/**
 * ----------------------------------------
 * animation slide-out-blurred-bottom
 * ----------------------------------------
 */
 /*
 @-webkit-keyframes slide-out-blurred-bottom {
    0% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
              transform: translateY(1000px) scaleY(2) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
}
@keyframes slide-out-blurred-bottom {
    0% {
      -webkit-transform: translateY(0) scaleY(1) scaleX(1);
              transform: translateY(0) scaleY(1) scaleX(1);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
      -webkit-filter: blur(0);
              filter: blur(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
              transform: translateY(1000px) scaleY(2) scaleX(0.2);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      -webkit-filter: blur(40px);
              filter: blur(40px);
      opacity: 0;
    }
}*/

.d-block{
    display: block;

}

.d-none{
    display: none;
}

.boxAlignCenter{
    text-align: center;
    display: flex;
    justify-content: center;
}

[data-component-name="Tabs"]{
    button[data-component-name="Tab"]{
        @media only screen and (min-width: 768px) {
            padding: 16px 16px!important;
        }
        span{
            font-weight: 400!important;
            font-size: 16px;

            @media only screen and (max-width: 768px) {
                white-space: nowrap;
            }
        }
        &[aria-selected="true"]{
            span{
                font-weight: 500!important;
            }
        }
    }
}

.content-public-area{
    @media (min-width: 1024px) {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &:before{
            content: '';
            width: 409px;
            height: 409px;
            background: url(../../public/assets/img/mask-bg-ellipse-left.svg) no-repeat;
            background-size: contain;
            background-position: center;
            position: absolute;
            top: calc(30% - 217px);
            left: -390px;
            z-index: 2;
            transition: all .4s;

            @media (min-width: 1369px) {
                left: -363px;
            }

            @media (min-width: 1465px) {
                left: -380px;
            }
        }

        &:after{
            content: '';
            width: 709px;
            height: 709px;
            background: url(../../public/assets/img/mask-bg-ellipse-right.svg) no-repeat;
            background-size: contain;
            background-position: center;
            position: absolute;
            top: calc(42% + 109px);
            right: -682px;
            z-index: 2;
            transition: all .4s;

            @media (min-width: 1369px) {
                right: -642px;
            }

            @media (min-width: 1465px) {
                right: -614px;
            }
        }
    }
}

.global-position-dontshow-link{
    margin-top: -40px;
    margin-left: 15px;
    position: absolute,
}

@media only screen and (min-height: 550px) and (min-width: 1024px){
    [data-component-name="ButtonSecondary"]{
        width: auto!important;
    }
    /*[data-component-name="Text3"]{
        word-wrap: unset!important;
        word-break: inherit!important;
    }*/
}

.mode-callcenter{
    position: fixed;
    top: 0;
    z-index: 1030;
    width: 100%;
    text-align: center;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #2A3F54;
        opacity: .3;
    }
}

*{
    hyphens:initial!important;
}