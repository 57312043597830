.registerContributions--content{
    .boxColor{
        .columnData{
            position: relative;

            span{
                width: 100%;
                display: inline-block;
                text-align: center;
            }
        }
    }

    [data-column-data="true"]{
        > div{
            position: relative;

            &:before{
                content: '';
                position: absolute;
                width: 1px;
                height: 100%;
                top: 0;
                left: -1px;
            }

            &:first-child{
                &:before{
                    display: none;
                }
            }
        }
    }
}