.containerNewDetail{
    height: 300px;

    &.withoutImage{
        height: 150px;
    }
}

.contentNewDetail{
    margin-top: -300px;

    &.withoutImage{
        margin-top: -190px;
        h1[data-component-name="Text7"]{
            min-height: 100px;
        }
    }
    .html-content{
        iframe{
            width:100%!important;
            height:420px!important;
        }
        img{
            max-width: 100%;
            height: auto!important;
        }
    }
}