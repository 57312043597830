.calculatorBox{
	max-width: 474px;
	width: 100%;

	.inline--inputInfo{
		grid-template-columns: 2fr;
	}
}

.calculatorPage--content{
	.result--box{
		.boxWidth{
			min-width: 90px;
			text-align: center;
	
			@media (max-width: 480px) {
				min-width: 70px;
			}
	
			&--color{
				border-radius: 8px;
			}

			&--xl{
				min-width: 150px;
			}
		}

		.inlineItems--end{
			justify-items: end;
		}

		.flexAlignCenter{
			@media (max-width: 768px) {
				display: flex;
				justify-content: center;
			}
		}
	}

	.result--card{
		.verticalDivider{
			width: 1px;
			height: 100%;
		}
	}
}