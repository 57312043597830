/******scroll-to-top**********/

.sticky-scroll-to-top{
    position: sticky;
    bottom:-90px;
    right: -90px;
    z-index: 9;
    transition: all 0.3s;
    opacity: 0;
    overflow-x: visible!important;

    &.visible{
        opacity: 1;

        bottom: env(safe-area-inset-bottom);
        //transform: rotate(90deg);
    }

    &.with-footer-menu{
        .button-scroll-to-top{
            bottom: 70px;
        }
    }
}
.button-scroll-to-top{
    position: absolute;
    right: 66px;
    bottom: 20px;


    @media only screen and (max-width: 768px) {
        right: 20px;
    }
}