.otherQueriesControlCommision--tabs{
    @media only screen and (max-width: 1024px){
        [data-component-name=Tabs]{
            button{
                &[data-component-name=Tab]{
                    white-space: nowrap;
                    max-width: 100%;
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                }
            }
        }
    }

    @media only screen and (min-width: 1024px){
        [data-component-name=Tabs]{
            button{
                &[data-component-name=Tab]{
                    padding-left: 8px !important;
                    padding-right: 8px !important;
                    max-width: calc(100% / 5);
                    text-wrap: balance;

                    span{
                        font-size: 14px !important;
                        line-height: 20px;
                    }
                }

                &:first-of-type{
                    &[data-component-name=Tab]{
                        padding-left: 0 !important;
                    }
                }

                &:last-of-type{
                    &[data-component-name=Tab]{
                        padding-right: 0 !important;
                    }
                }
            }
        }
    }
}

.otherQueriesControlCommision--content{
    .boxValue{
        text-align: center;
        min-width: 196px;

        @media (max-width: 576px){
            min-width: 0;
        }

        &--color{
            border-radius: 8px;
        }

        &--center{
            @media (max-width: 992px){
                text-align: center;
            }
        }
    }

    &.loading{
        opacity: 0.7;
        pointer-events: none;
    }
}

.table--otherQueriesControlCommision{
    .row{
        margin: 6px 0 8px;
    }

    .col-1{
        width: 160px;
    }

    .col-2,
    .col-3{
        width: 260px;
        text-align: center;
    }

    .col-4{
        width: 90px;
        text-align: center;
    }
}