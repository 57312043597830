.globalPositionPromoter--content{
    .flexWidth{
        display: flex;
        justify-content: center;
    }

    .boxWidth{
        > span{
            width: 100%;
            display: inline-block;
            text-align: center;
        }
    }
}