

.fingerprintApp{
     height: 100vh;
     padding-bottom:env(safe-area-inset-bottom);
    &__content{
         height: calc(100vh - 142px - env(safe-area-inset-bottom));
    }

    [data-component-name="Text3"]{
         width: 100%;
         display: inline-block;
         text-align: center;
    }
 }