

.mainSearcher{
    max-height: 0;
    overflow-y: hidden;
    opacity: 0;
    transition: all .4s;
    z-index: 26;

    &.visible{
      overflow-y: visible;
      max-height: 200px;
      opacity: 1;

      // position: fixed;
      // background: #fff;
      // z-index: 3;
      // width: 100%;
    }

    &--fixed{
      position: fixed;
      width: 100%;
      z-index: 3;
    }

    &__inline{
      grid-template-columns: 2.4fr .1fr;
      position: relative;
    }

    &__input{
      width: calc(100% - 24px);

      @media (max-width: 1024px) {
        width: calc(100% - 16px);
      }
      [data-component-name="IconButton"]{
        display: none!important;
      }

    }

    &__suggestions{
      position:absolute;
      width: calc(100% - 194px);
      left:0;
      top:60px;
      z-index: 9;
      box-shadow: 0px 0px 7px 0px rgba(42, 42, 60, 0.20);

      @media (max-width: 1224px) {
        width: calc(100% - 185px);
      }

      @media (max-width: 1024px) {
        width: calc(100%);
      }
    }

    &__collapsible{
      position: fixed;
      width: 100%;
      height: calc(100vh - 85px);
      overflow: hidden;
      top: 84px;
      z-index: 8;

      &--empty{
        overflow-y: auto;

        .mainSearcher__totalResults{
          padding-top: 49px;
        }
      }
    }

    &__totalResults{
      padding-top: 33px;
    }

    &__itemsResults{
      max-height: calc(100vh - 193px);
      overflow-y: auto;

      [data-component-name=Tabs]{
        height: 24px;
        border: 0;

        button{
          &[data-component-name=Tab]{
            height: 24px;
            padding: 0px 0 0 !important;
          }
        }
      }
    }

    &__optionsLink{
      max-width: 915px;
      width: 100%;
    }

    .groupLinks{
      position: relative;

      &:first-of-type{
        &::before{
          display: none;
        }
      }

      &:before{
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        left: -80px;
        top: 0;

        @media (max-width: 1024px) {
          width: 100%;
          height: 1px;
          left: 0;
          top: -16px;
        }
      }
    }

    .verticalDivider{
      position: relative;
      width: 100%;
      height: 100%;

      &:before{
        content: '';
        width: 1px;
        height: calc(100% + 34px);
        position: absolute;
        left: 0;
        top: -12px;
      }
    }
  }

  #header{
    .menu-nav{
      &.fixed{
        .mainSearcher{
          position: fixed;
          width: 100%;

          [data-component-name=IconButton]{
            display: inline-block!important;
          }
        }
      }
    }
  }