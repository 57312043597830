.contractData--content{
    .html-content{
        ul,
        ol{
            margin: 0;

            li{
                margin: 0;
                font-size: 14px;
                line-height: 20px;
            }
        }

        ul{
            list-style-type: none !important;
            padding-left: 23px;

            li{
                position: relative;

                &:before{
                    content: '';
                    position: absolute;
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background-color: currentColor;
                    top: 9px;
                    left: -13px;
                }
            }
        }
    }
}