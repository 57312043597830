.contact-map{
    position: relative;
    z-index: 1;
} 

.contact-info{
    position: relative;
    z-index: 2;
    margin-top: -100px;
    background-color: rgba(255, 255, 255, 0.8);
}