.movements--content{
    .blockFilters{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        @media (max-width: 1024px){
            flex-direction: column;
        }

        .filterGroup{
            display: flex;
            gap: 16px;
            align-items: center;

            @media (max-width: 1024px){
                flex-direction: column;
                width: 100%;
            }

            &--options{
                @media (max-width: 1024px){
                    flex-direction: row;

                    button{
                        width: 50%;
                    }

                    &.one-button{
                        button{
                            width: 100%;
                        }
                    }
                }
            }
        }

        .group--date{
            min-width: 368px;

            @media (max-width: 1024px){
                min-width: 0;
                width: 100%;
            }
        }

        .group--button{
            @media (max-width: 1024px){
                width: 100%;
            }
        }
    }
}

.table--movements{
    .row{
        margin: 6px 0 8px;
    }

    .col{
        width: 125px;
        text-align: center;
    }

    .col-tag,
    .col-icon{
        width: 65px;
        text-align: center;
    }
}

.detail-movements{
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;
    margin-bottom: -24px;

    &.visible{
        max-height: 100vh;
        opacity: 1;
    }
}