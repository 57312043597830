.guides-reports{
    .reportsBoxed{
        height: 100%;
    }

    .html-content{
        ul, ol{
            margin-top: 0;
            padding-left: 23px;
            text-align: inherit;

            li{
                font-size: 16px;
                line-height: 24px;
                margin: 0;
                text-align: inherit;

                @media (max-width: 1024px) {
                    font-size: 14px;
                }
            }
        }
    }
}