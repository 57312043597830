.landingPage--content{
    .html-content{
        p{
            font-size: 20px;
            line-height: 28px;
            font-weight: 300;
            margin-bottom: 24px;

            @media (max-width: 1024px){
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 16px;
            }
        }

        ul,
        ol{
            margin: 0;

            li{
                font-size: 20px;
                line-height: 28px;
                font-weight: 300;
                margin-bottom: 24px;

                @media (max-width: 1024px){
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 16px;
                }
            }
        }

        ul{
            list-style-type: none !important;
            padding: 0;

            li{
                position: relative;
                padding-left: 24px;

                &:before{
                    content: '';
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    border-radius: 100%;
                    top: 11px;
                    left: 6px;
                    background: currentColor;
                }
            }
        }

        &.hero--extraContent{
            margin-top: 16px;

            p{
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
    
                @media (max-width: 1024px){
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            a{
                color: currentColor !important;
                text-decoration: underline;
                transition: all .4s;

                &:hover{
                    opacity: .8;
                }
            }

            ul{
                li{
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    margin-bottom: 16px;
                    padding-left: 20px;

                    &:before{
                        width: 3px;
                        height: 3px;
                        top: 11px;
                        left: 6px;
                        background: currentColor;
                    }
        
                    @media (max-width: 1024px){
                        font-size: 16px;
                        line-height: 24px;
                        margin-bottom: 16px;
                    }
                }
            }
        }

        &.dataCard--extraContent{
            margin-top: 16px;
            
            p{
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
    
                @media (max-width: 1024px){
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            ul{
                li{
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    margin-bottom: 0;
                    text-align: inherit;
                    padding-left: 20px;

                    &:before{
                        width: 3px;
                        height: 3px;
                        top: 11px;
                        left: 6px;
                        background: currentColor;
                    }
                }
            }
        }

        &.boxIcon--content{
            p{
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
                margin-bottom: 0;
                text-align: center;
    
                @media (max-width: 1024px){
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            ul{
                li{
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 400;
                    margin-bottom: 0;
                    text-align: center;

                    &:before{
                        content: '•';
                        position: relative;
                        color: currentColor;
                        background-color: transparent;
                        top: inherit;
                        left: inherit;
                        margin-right: 8px;
                    }
                }
            }
        }

        &.chipTabs--content{
            h2{
                font-size: 40px;
                line-height: 48px;
                font-weight: 300;
                margin: 32px 0;

                @media (max-width: 1024px){
                    font-size: 28px;
                    line-height: 32px;
                    margin: 24px 0;
                }
            }

            h3{
                font-size: 32px;
                line-height: 40px;
                font-weight: 300;
                margin: 24px 0;

                @media (max-width: 1024px){
                    font-size: 24px;
                    line-height: 32px;
                    margin: 16px 0;
                }
            }

            h4{
                font-size: 28px;
                line-height: 32px;
                font-weight: 300;
                margin: 24px 0;

                @media (max-width: 1024px){
                    font-size: 20px;
                    line-height: 24px;
                    margin: 16px 0;
                }
            }

            ol,
            ul{
                margin-bottom: 24px;

                li{
                    font-size: 20px;
                    line-height: 28px;
                    margin-bottom: 0;

                    @media (max-width: 1024px){
                        font-size: 18px;
                        line-height: 24px;
                        margin-bottom: 0;
                    }
                }
            }

            ol{
                padding-left: 30px;
            }

            p{
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 0;

                @media (max-width: 1024px){
                    font-size: 18px;
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }
        }

        &.hero--hiddenImage{
            visibility: hidden !important;
        }
    }

    .bg--alternative{
        .html-content{
            &.hero--extraContent{
                p{
                    margin-bottom: 0;
                }

                ul{
                    margin-bottom: 16px;

                    li{
                        margin-bottom: 0;
                        margin-top: 0;

                        @media (max-width: 1024px){
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .blueText--block,
    .limit--block{
        max-width: 890px;
        width: 100%;
        margin: 0 auto;
    }

    .BoxIcon{
        max-width: 288px;
        width: 100%;
    }

    .isotopeGrid{
        position: relative;

        &:before{
            content: '';
            width: 100%;
            height: 100%;
            max-height: 480px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }

        &__content{
            position: relative;
            z-index: 2;
        }

        &__buttons{
            gap: 32px;
            justify-content: center !important;
        }
    }

    .fullImage--block{
        max-width: 1115px;
        width: 100%;
        margin: 0 auto;
        text-align: center;

        > div{
            display: flex;
            justify-content: center;
            aspect-ratio: auto;
        }

        img{
            max-height: 586px;
            position: relative;
            object-fit: contain;

            @media (max-width: 768px){
                max-height: 100%;
                max-width: 375px;
                width: 100%;
            }
        }

        &.screenSize--same{
            max-width: 463px;
        }
    }

    .hero--reduceSize{
        margin-bottom: -160px;
        margin-top: -140px;
    }

    .landingPageERE--subtitle{
        margin-top: 20px;
        
        p{
            margin-bottom: 8px !important;

            @media (max-width: 1024px){
                margin-bottom: 16px;
            }
        }
    }

    .landingPageERE--chipSkeleton{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .landingPageIRPF--containerVideos{
        margin: 0 auto;

        .leftVideo{
            width: 49%;
            height: 350px;
            margin-right: 20px;
        }

        .rightVideo{
            width: 49%;
            height: 350px;
        }

        @media only screen and (max-width: 1025px) {
            .leftVideo,
            .rightVideo {
                width: 100%;
                max-width: 600px;
                margin: 20px auto 0; 
                float: none; 
            }
        }
    }

    .landingPageIRPF--containerSingleVideo{
        max-width: 800px;
        margin: 0 auto;
        width: 60%;

        .video{
            width: 100%;
            height: 350px;
        }
    }
}