.numbers-container{
    overflow: hidden;
}

.numbers--content{
    position: relative;

    >div{
        position: relative;
        z-index: 1;
    }

    &:after{
        content: '';
        position: absolute;
        /*width: 1676px;
        height: 358px;
        top: -84px;
        left: calc(50% - 832px);*/
        width: 100%;
        height: 100%;
        top: -250px;
        left: 0;
        background: url(../../../public/assets/img/intersect.svg) no-repeat;
        background-size: contain;
        background-position: center;
        transform: rotate(3deg);
        z-index: 0;
    }

    .itemNumber{
        @media (max-width: 1024px){
            //margin: 0 16px;
        }

        > div{
            min-height: 308px;

            @media (max-width: 768px){
                min-height: 250px;
            }
        }

        &:first-of-type{
            // margin-left: 16px;
        }
    }
}