.generalInformation--content{
    .html-content{
        *{
            font-size: 20px;
            line-height: 28px;
            font-weight: 300;
        }

        p{
            margin-bottom: 0;
        }

        ul,
        ol{
            margin: 0;

            li{
                margin: 0;
            }
        }

        ul{
            list-style-type: none !important;

            li{
                position: relative;

                &:before{
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background-color: currentColor;
                    top: 12px;
                    left: -17px;
                }
            }
        }
    }
}