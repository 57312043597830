.accordion-item{
    button[data-component-name="Row"]{
        background: none;

        > div{
            padding: 0 !important;
        }
    }
    .accordion-content-item{
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        &.visible{
            opacity: 1;
            max-height: 400vh;
            padding:inherit;
        }
    }

}
