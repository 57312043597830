.annualEvolutionGraph--content{
    .boxWidth{
        min-width: 90px;
        text-align: center;

        @media (max-width: 480px) {
            min-width: 70px;
        }

        &--color{
            border-radius: 8px;
        }
    }

    .chartLegend{
        

        > div{
            
        }
    }

    &.fullBlock{
        .chartLegend{
            width: 100%;
            display: flex;
            justify-content: flex-end;
    
            > div{
                max-width: 470px;
                width: 100%;

                @media (max-width: 1024px) {
                    max-width: 94%;
                    margin: 0 auto;
                }

                @media (max-width: 768px) {
                    max-width: 100%;
                }
            }
        }
    }
}