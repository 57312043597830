.dropzone--content{


    .boxed-dashed{
        border-style: dashed;
        transition: all .24s ease-in-out;
    }

    .text-center{
        text-align: center;
    }
}