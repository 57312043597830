.wrapper-stepper{
    .stepper{
        max-width: 600px;
        width: 100%;
        margin:34px auto 14px;
        padding: 0 20px;
        > div{
            max-width: 600px;
            margin: 0 auto;
        }
    }
    position: fixed;
    z-index: 3;
    transform: translate3d(0,0,0);
}

.form-register-page{
    padding:150px 0 72px;
    min-height: calc(100vh - 80px);

    &.with-iframe{
        padding:32px 0;
        #iframeSignaturit{
            height:calc(100vh - 150px);
        }
    }

    @media only screen and (max-width: 1023px) {
        min-height: calc(100vh - 57px);
        #iframeSignaturit{
            height:calc(100vh - 130px);
        }
    }

    &.mobile{
        padding-top:92px;
        &.with-iframe{
            padding:22px 0;
            #iframeSignaturit{
                height: calc(100vh - 110px);
            }
        }
    }
}

.form{
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    > div{
        max-width: 800px;
        margin: 0 auto;
    }
    &.large{
        max-width: 832px;
        margin: 0 auto;

        > div{
            max-width: 832px;
            margin: 0 auto;
        }
    }
    &.extra-large{
        max-width: 912px;
        margin: 0 auto;

        > div{
            max-width: 912px;
            margin: 0 auto;
        }
    }
}

.double-cards{
    max-width: 600px;
    width: 100%;
    margin:0 auto;
    display: flex;
}

.contain-politics-text{
    background: #F6F6F6;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    padding: 8px 16px 8px 12px;
    height: 152px;
    overflow: auto;
    line-height: 30px;
    font-size: 16px;
    ul{
        padding-left: 20px;
    }

}