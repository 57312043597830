.carouselItem{
  display: flex;
}

.tooltip-carousel-history {
    border-radius: 10px;
    padding: 16px 24px;
    width: 94px;
    text-align: center;
  }
