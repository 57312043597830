.plt-ios{
  #header-private{
    @supports (padding-bottom: env(safe-area-inset-bottom)) {
      position: fixed;
    }
  }
}

#header-private{
  z-index: 3;
  position: relative;


  @media only screen and (max-width: 1024px) {
    z-index: 10;
  }

  .menu-nav{
    &.fixed{
      [data-component-name="IconButton"]{
        display: none!important;
      }
      .logo{
        margin-left: 0;
      }
    }
  }

  .profile-nav{
    width:100vw;
    height: 120px;
    
    &.fixed{
      position: fixed;
      transform: translate3d(0,0,0);
      padding-top: calc(env(safe-area-inset-top) / 1.3);
      background: linear-gradient(256.63deg, #0066FF 54.3%, rgba(0, 148, 255, 0.79) 160.25%)!important;

      &:after{
        content:'';
        background-image: url('../../../public/assets/img/header-private-big-line.svg');
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 120%;
        margin: 0 auto;
        max-width: 1224px;
        left: calc((100vw - 1224px)/2);
        z-index: -1;
      }

      @media only screen and (max-width: 1024px) {
        background: linear-gradient(156.74deg, #0066FF 26.27%, #0094FF 128.9%)!important;

        &:after{
          background-image: url('../../../public/assets/img/header-private-small-line.svg');
          left: 0;
          height: 70%;
          background-position: bottom right;
          top: 30%;
        }
      }
    }


    @media only screen and (max-width: 1024px) {
      height:calc(106px + env(safe-area-inset-top));
    }

    &.small{
      height: 44px;
      &::after{
        display: none;
      }
      @media only screen and (max-width: 1024px) {
        height:calc(44px + env(safe-area-inset-top)/1.2);
      }

      .title-center{
        width: calc(100vw - 32px);
        &.with-back{
          width: calc(100vw - 120px);
          @media only screen and (max-width: 480px) {
            width:calc(100vw - 115px)
          }
        }
        span{
          /*width: 100%;
          display: inline-block;*/
        }
      }
    }
  }

  .private-nav{
    &.fixed{
      position: fixed;
      transform: translate3d(0,0,0);
      max-height: calc(100vh - 204px);
      max-width: 307px;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: calc(100vh - 198px);

      &:before{
        content: '';
        width: calc(100% - 31px);
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &::-webkit-scrollbar-track{
          border: none;
          background-color: transparent;
      } 

      &::-webkit-scrollbar{
          width: 2px;
          background-color: rgba(#d1d5e4, 100%);
          border-radius: 50px;
      }

      &::-webkit-scrollbar-thumb{
          background-color: darken(#d1d5e4, 20%);
          border-radius: 50px;
      }

      &::-webkit-scrollbar-thumb:hover{
          background-color: lighten(#d1d5e4, 20%);
          border: 1px solid lighten(#d1d5e4, 20%);
      }

      &::-webkit-scrollbar-thumb:active{
          background-color: lighten(#d1d5e4, 20%);
          border: 1px solid lighten(#d1d5e4, 20%); 
      } 

      @media only screen and (max-width: 1223px) {
        max-width: calc(288px + 30px);

      }

      > div:first-child{
        width: calc(100% - 31px);
        position: relative;
        z-index: 1;
      }
    }
    &.collapsed{
      left:-288px;
      transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      max-height: 150px;
      overflow: hidden;
    }
    &.active{
      left: 0px;
      max-height: calc(100vh - 204px);
      overflow-y: auto;
    }
    .option-selected{
        width:4px;
        height:72px;
        position: relative;
        right: -18px;
    }

    .private-nav-button{
      width: 37px;
      position: absolute;
      z-index: 0;
      top: 64px;
      right: 0px;

      .hideMenu{
        height: 86px;

        &__text{
          height: 100%;
          position: relative;
          padding: 10px 4px 9px 9px !important;

          &:after{
            content: attr(data-after-content);
            position: absolute;
            transform: rotate(90deg);
            bottom: 19px;
            left: 2px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
    }

  }

  .menu-vertical{
    background-image: url('../../../public/assets/img/mask-bg-menu.svg');
    background-repeat: no-repeat;
    background-position: -80vw 50vh;
    height: 100vh;
    overflow: auto;
    position: fixed;
    transform: translate3d(0,0,0);
    z-index: 999;
    background-color: #fff;
    padding-bottom: 70px;
    z-index: 4;


    top:-100vh;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 0;

    &.visible{
      top:0px;
      opacity: 1;
    }
    .option-selected{
        width:4px;
        height:72px;
        position: relative;
        right: -16px;
    }

  }
}

.user-menu-fixed{
  position: fixed;
    transform: translate3d(0,0,0);
  z-index: 9;
  margin-top:-50vh;
  transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 0;

  &.visible{
    margin-top:-4px;
    opacity: 1;
  }
}