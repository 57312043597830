.doughnutGraph--content{
    .selectBox{
        max-width: 240px;
        width: 100%;

        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &.loading{
        opacity: 0.7;
        pointer-events: none;
    }
}

.portfolio-composition-chart{
    max-width: 500px;
    min-height: 400px;
    margin: 0 auto;

    @media only screen and (max-width: 600px){
        min-height: 250px;
    }
}