.table--contractsCustomer{
    .row{
        margin: 6px 0 8px;
    }

    .col-1{
        width: 260px;
    }

    .col-2{
        width: 160px;
        text-align: center;
    }

    .col-3{
        width: 90px;
        text-align: center;
    }

    @media only screen and (min-width: 1190px){
        .col-1{
            width: 500px;
        }
    }
}