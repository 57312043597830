.initApp{
    width:100vw;
    height: 100vh;
    overflow: hidden;
    padding-top: env(safe-area-inset-top);
    padding-bottom: calc(env(safe-area-inset-bottom) / 8);

    > div{
        height: 100%;
    }

    [data-component-name="Carousel"]{
        pointer-events: none;
        button[data-component-name="Touchable"]{
            display: none!important;
        }
        [data-item="true"]{
            width:100vw !important;
            scroll-margin:0;
        }
    }

    &__header{
        position: relative;
        width: 100%;
        height: 375px;
        z-index: 1;

        @media (max-height: 590px){
            height: 300px;
        }

        @media (max-height: 520px){
            height: 252px;
        }

        @media (max-height: 470px){
            height: 200px;
        }

        &:before{
            content: '';
            position: absolute;
            width: 405px;
            height: 405px;
            top: -114px;
            left: -88px;
            background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,102,255,1) 100%);
            border-radius: 50%;
            z-index: 1;
            transition: all .4s;

            @media (max-height: 590px){
                width: 315px;
                height: 315px;
            }

            @media (max-height: 520px){
                width: 265px;
                height: 265px;
            }

            @media (max-height: 470px){
                opacity: .4;
            }
        }

        &:after{
            content: '';
            position: absolute;
            width: 403px;
            height: 403px;
            top: -113px;
            left: -87px;
            background: #fff;
            border-radius: 50%;
            z-index: 2;
            transition: all .4s;

            @media (max-height: 590px){
                width: 313px;
                height: 313px;
            }

            @media (max-height: 520px){
                width: 263px;
                height: 263px;
            }
        }

        .image{
            position: absolute;
            z-index: 5;
            top: 33px;
            left: -95px;
            transition: all .3s;
            opacity: 1;
        }

        .image-step-1{
            position: absolute;
            z-index: 4;
            top: 33px;
            left: -95px;
            transition: all .3s;
            opacity: 0;
        }

        .image-step-2{
            position: absolute;
            z-index: 3;
            top: 33px;
            left: -95px;
            transition: all .3s;
            opacity: 0;
        }

        &.step-1{
            &:before{
                width: 372px;
                height: 372px;
                top: -37px;
                left: 14px;

                @media (max-height: 590px){
                    width: 282px;
                    height: 282px;
                }

                @media (max-height: 520px){
                    width: 232px;
                    height: 232px;
                }
            }

            &:after{
                width: 370px;
                height: 370px;
                top: -36px;
                left: 15px;

                @media (max-height: 590px){
                    width: 280px;
                    height: 280px;
                }

                @media (max-height: 520px){
                    width: 230px;
                    height: 230px;
                }
            }

            .image{
                left: calc(100% - 280px);
                top: 33px;
                opacity: 0;
            }

            .image-step-1{
                left: calc(100% - 280px);
                top: 33px;
                opacity: 1;
            }

            .image-step-2{
                left: calc(100% - 280px);
                top: 33px;
                opacity: 0;
            }
        }

        &.step-2{
            &:before{
                width: 409px;
                height: 409px;
                top: 19px;
                left: calc(100% - 256px);
                background: linear-gradient(120deg, rgba(255,255,255,1) 0%, rgba(0,102,255,1) 100%);

                @media (max-height: 590px){
                    width: 319px;
                    height: 319px;
                }

                @media (max-height: 520px){
                    width: 269px;
                    height: 269px;
                }
            }

            &:after{
                width: 407px;
                height: 407px;
                top: 20px;
                left: calc(100% - 255px);

                @media (max-height: 590px){
                    width: 317px;
                    height: 317px;
                }

                @media (max-height: 520px){
                    width: 267px;
                    height: 267px;
                }
            }

            .image{
                left: 16px;
                top: 35px;
                opacity: 0;
            }

            .image-step-1{
                left: 16px;
                top: 35px;
                opacity: 0;
            }

            .image-step-2{
                left: 16px;
                top: 35px;
                opacity: 1;
            }
        }
    }

    &__slider{
        position: relative;
        z-index: 2;
    }
}