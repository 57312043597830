.plt-ios{
    .page{
        &.private{
            .content-private-area{

                @supports (padding-bottom: env(safe-area-inset-bottom)) {
                    padding-top:calc(44px + env(safe-area-inset-top)/1.2);

                    &.big{
                        padding-top: calc(120px + env(safe-area-inset-top) / 1.3);
                    }
                }
            }
        }
    }

    .content-public-area{
        &.with-header{
            @supports (padding-bottom: env(safe-area-inset-bottom)) {
                padding-top:calc(44px + env(safe-area-inset-top)/1.2);
            }
        }
    }
}

.page{
    &.private{
        >div{
            overflow-x: hidden;
        }
        .content{
            width: 100vw;
            overflow-x: hidden;
        }
        .content-private-area{
            min-height: 400px;
            >div{
                max-width: inherit;
            }

            &.with-footer-menu{
                padding-bottom: 61px;
            }

            @media only screen and (max-width: 768px) {
                .fullscreen{
                    padding: 16px;
                    left: -16px;
                }
            }

            .full{
                width:100vw;
                left:-16px;
                padding: 0 16px;
                position: relative;
            }

            .padding--none{
                padding: 0 !important;
            }


            @media only screen and (min-width: 768px) {
                .fullscreen{
                    padding: 32px;
                    left: -32px;
                }
                .full{
                    left:-32px;
                    padding: 0 32px;
                }
            }

            @media only screen and (min-width: 1024px) {
                .fullscreen{
                    &.inner{
                        padding: 0 40px;
                        left: -40px;
                    }
                }
            }

            @media only screen and (min-width: 1224px) {
                .fullscreen{
                    &.inner{
                        padding: 0 0 0 46px;
                        left: 0;
                    }
                }
            }

            @media only screen and (min-width: 1368px) {
                .fullscreen{
                    &.inner{
                        padding-left: 96px;
                    }
                }
            }

            @media only screen and (min-width: 1024px) {
                position: relative;


                left:0;
                .inner{
                    width: 100%;
                    padding-left: 0px;
                }
                .full{
                    [data-component-name="Carousel"]{
                        width: 100%;
                    }
                    left:-40px;
                    padding: 0 40px;
                }
            }
            @media only screen and (min-width: 1224px) and (min-height: 550px) {
                left: 288px;

                .inner{
                    width: calc(100% - 288px);
                }
                .full{
                    left:0;
                    max-width: calc(100vw - ((100vw - 100%)/2 + 288px));
                    [data-component-name="Carousel"]{
                        width:calc(100% - 45px);
                    }
                }

                .inner,
                .full{
                    padding-left: 46px;
                    .inner{
                        padding-left: 0px;
                        width:100%;
                        [data-component-name=Carousel] {
                            width: 100%;
                        }
                    }
                }

                .full{
                    width: calc(100vw - ((100vw - 100%)/2 + 288px));
                }
            }
            @media only screen and (min-width: 1368px) and (min-height: 550px) {
                .full{
                    width: calc(100vw - ((100vw - 1224px)/2 + 288px));
                    [data-component-name="Carousel"]{
                        width:calc(100% - 72px);
                    }
                    .inner{
                        [data-component-name="Carousel"]{
                            width:calc(100% - 72px);
                        }
                    }
                }

                .inner,
                .full{
                    padding-left: 74px;
                    .inner{
                        padding-left: 0px;
                        width:calc(100vw - (100vw - 1224px + 288px + 74px));
                    }
                }
            }
            .inner,
            .full{
                &.first{
                    padding-top: 32px;
                }
                >div{
                    max-width: inherit;
                }
            }
            .fullscreen{
                width: 100vw;
                position: relative;
            }

        }
    }
}