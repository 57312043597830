.empty-card{
    text-align: center;

    ._1wpwyyp1{
        margin: 0 auto;
    }

    .boxContent{
        // max-width: 392px;
        max-width: 460px;
        width: 100%;
    }
}