.form-login-page{
    max-width: 1016px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    min-height: calc(100vh - 161px);


    &::before{
        content:'';
        background: url('../../../../public/assets/img/mask-bg-colours.svg') no-repeat;
        width: 100vw;
        height: calc(100% + 180px);
        left: calc((-100vw + 100%)/2);
        margin: auto;
        position: absolute;
        top: -180px;
        z-index: -1;
    }
}
.nativeapp{
    .form-login-page{
        min-height: 100vh;
        padding-top: env(safe-area-inset-top);
        padding-bottom: env(safe-area-inset-bottom);
    }
}