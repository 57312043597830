.faqs--container{
    [data-component-name="RowList"]{
        position: relative;

        @media (max-width: 1024px) {
            left: -32px;
            width: calc(100% + 64px);
        }

        @media (max-width: 768px) {
            left: -16px;
            width: calc(100% + 32px);
        }

        &:before{
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    [data-list-menu="true"]{
        button{
            position: relative;

            @media (max-width: 1024px) {
                padding-left: 16px;
                padding-right: 16px;
            }

            > div,
            + div{
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .hr--vertical{
        position: absolute;
        top: 0;
        right: 0;
        width: 4px;
        height: 100%;
    }

    .pane-menu{
        position: relative;

        &:before{
            content: '';
            position: absolute;
            width: 1px;
            height: calc(100% - 80px);
            top: 0;
            left: -25px;

            @media (max-width: 1368px) {
                left: -16px
            }

            @media (max-width: 1024px) {
                display: none;
            }
        }
    }

    .html-content{
        p{
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;

            &:last-of-type{
                margin-bottom: 0;
            }

            @media (max-width: 1024px) {
                font-size: 14px;
            }

            strong{
                font-weight: 600;
            }
        }
    }
}