.summaryOperate--content{
    .boxResults{
        @media (min-width: 1024px){
            &.box--auto{
                width: auto;
                display: inline-block;
        
                .boxValue{
                    min-width: 170px;
                }
            }
        }

        .boxValue{
            position: relative;
            text-align: center;

            @media (max-width: 1024px){
                text-align: left;
            }

            &:before{
                content: '';
                position: absolute;
                width: 1px;
                height: 100%;
                top: 0;
                left: -3.5%;

                @media (max-width: 1024px){
                    display: none;
                }
            }

            &.box--first{
                &:before{
                    display: none;
                }
            }
        }
    }
}