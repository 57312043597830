.profitabilityValues--content{
    .boxValue{
        text-align: center;
        min-width: 196px;

        @media (max-width: 576px){
            min-width: 0;
        }

        &--color{
            border-radius: 8px;
        }

        &--center{
            @media (max-width: 992px){
                text-align: center;
            }
        }
    }

    [data-center="true"]{
        @media (max-width: 992px){
            row-gap: 22px !important;
            position: relative;
        }

        .divider{
            position: relative;

            @media (max-width: 992px){
                position: inherit;
            }

            &:before{
                content: '';
                position: absolute;
                width: 1px;
                height: 100%;
                top: 0;
                left: -50px;

                @media (max-width: 992px){
                    height: 55px;
                    left: 50%;
                }
            }
        }
    }
}