.historicalProfitability--content{
    .boxWidth{
        min-width: 90px;
        text-align: center;

        @media (max-width: 480px) {
            min-width: 70px;
        }

        &--color,
        &--lightColor{
            border-radius: 8px;
        }

        &--lightColor{
            min-width: 196px;
        }
    }

    .inlineItems--end{
        justify-items: end;
    }

    .inlineItems--endDesktop{
        @media (min-width: 1024px) {
            justify-items: end;
        }
    }

    .center--movile{
        @media (max-width: 1024px) {
            display: flex !important;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
}