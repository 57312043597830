#footer{
    position: relative;
    a{
      text-decoration: none!important;
      font-size: 14px;
      line-height: 20px;
    }

    >div{

        position: relative;
        z-index: 1;
    }
    &:before{
        content:'';
        background-image: url('../../../public/assets/img/mask-bg-footer.svg');
        width: 1440px;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        bottom: 0;
        right: 0;
        z-index: 0;
        margin: auto;
        transform: scaleX(-1);
        background-position: left bottom;

        @media only screen and (min-height: 550px) and (min-width: 1024px){
            transform: scaleX(1);
            background-position: right bottom;
        }
    }
}