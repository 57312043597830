.filter-button--onlineCorrespondence{
    position: fixed;
    transform: translate3d(0,0,0);
    right: 16px;
    width: auto!important;
    z-index: 999;
    top: 10px;

    &.is-native-app{
        &.is-ios{
            top: -4px;
            margin-top: env(safe-area-inset-top);
        }
    }
}

.filter--onlineCorrespondence{
    &.fixed{
        max-height: 77vh;
        overflow: auto;
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        padding: 16px 16px 0!important;
        transform: translate3d(0,0,0);
        position: fixed!important;
        width: 100vw!important;
        left:0!important;
        margin-top: -100vh;

        &.visible{
            opacity: 1;
            margin-top: 0;
            z-index: 1;
        }

    }
}

.table--onlineCorrespondence{
    .row{
        margin: 6px 0 8px;
    }

    .col-5{
        width: 65px;
        text-align: center;
    }

    @media only screen and (min-width: 1024px){
        .col-1,
        .col-2{
            width: 140px;
        }

        .col-3{
            width: 150px;
        }

        .col-4{
            width: 75px;
        }
    }

    @media only screen and (min-width: 1190px){
        .col-1,
        .col-2{
            width: 220px;
        }

        .col-3{
            width: 145px;
        }

        .col-4{
            width: 130px;
        }
    }

    @media only screen and (min-width: 1368px){
        .col-1,
        .col-2{
            width: 230px;
        }

        .col-3{
            width: 195px;
        }

        .col-4{
            width: 120px;
        }
    }
}