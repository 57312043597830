.risk--content{
    border-radius: 16px;

    [data-center='true']{
        width: 100%;
        display: inline-block;
    }

    .infoBox{
        max-width: 154px;
        width: 100%;

        &.align-right{
            text-align: right;
        }
    }
}